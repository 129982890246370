import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';

import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './redux/store.js'
import { socket } from './config/socketConnectivity.js';
import SocketContext from "./context/SocketContext";

ReactDOM.render(
  <BrowserRouter basename="/">
    <Provider store={store}>
      <SocketContext.Provider value={{ socket }}>
        <App />
      </SocketContext.Provider>
    </Provider>
  </BrowserRouter >
  , document.getElementById('root'));

serviceWorker.unregister();