export const environment = 'local'
export const buildEnv = 0
export const build = [
    {
        SOCKET_URL: 'https://backend.altranz.ai',
        backendUrl: 'https://backend.altranz.ai/Altranz-bot/v1',
        CRYPTO_SECKEY: "ASWE6YHDU6HUE9783U3U39393",
        imageUrl: 'https://backend.altranz.ai/',
        stripeSecretKey: 'pk_test_51Om9aoSALzhP9YmgSjHOnYeBj6hmBhsgduWqyGE35yKBTsc0N05l13hs7ojfwdofl7B2wi0SsyXMTizm4FEJxQGt00UWs6e5T4',
        chains: [56, 97, 137]
    },
    {
        SOCKET_URL: 'https://backend-alternance.maticz.in',
        backendUrl: 'https://backend-alternance.maticz.in/Altranz-bot/v1',
        CRYPTO_SECKEY: "ASWE6YHDU6HUE9783U3U39393",
        imageUrl: 'https://backend-alternance.maticz.in',
        stripeSecretKey: 'pk_test_51Om9aoSALzhP9YmgSjHOnYeBj6hmBhsgduWqyGE35yKBTsc0N05l13hs7ojfwdofl7B2wi0SsyXMTizm4FEJxQGt00UWs6e5T4',
        chains: [56, 97, 137]
    },
    {
        SOCKET_URL: 'http://localhost:3002',
        backendUrl: 'http://localhost:3002/Altranz-bot/v1',
        CRYPTO_SECKEY: "ASWE6YHDU6HUE9783U3U39393",
        imageUrl: 'http://localhost:3002/',
        stripeSecretKey: 'pk_test_51Om9aoSALzhP9YmgSjHOnYeBj6hmBhsgduWqyGE35yKBTsc0N05l13hs7ojfwdofl7B2wi0SsyXMTizm4FEJxQGt00UWs6e5T4',
        chains: [56, 97, 137]
    }
]
