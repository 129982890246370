// import package
import io from 'socket.io-client';

// import lib
// import config from './env';
// import toast from 'react-hot-toast';
import { build, buildEnv } from "../config/config";
// console.log(config, 'http://localhost:2002');
const socket = io(build[buildEnv].SOCKET_URL, { transports: ['polling'] });

const createSocketUser = (userId) => {
    socket.emit('CREATEROOM', userId)
}
// socket.on('disconnect', (reason) => {
//     toast.error('Your network is too low, please reload the page')
// });

// Support Ticket Chat-------------------------------->

const sendReply = (ticketId) => {
    socket.emit('createRoomForChat', ticketId)
}
const sendMessage = (ticketId) => {
    socket.emit('sendMessage', ticketId)
}

// useEffect(() => {
//   socket.on('ticketChatMessage', data => {
//     setMessage(data.message)
//   })

//   socket.on('receiveMessage', data => {
//     setMessage(data.message)
//   })
// })



export {
    socket,
    createSocketUser
}