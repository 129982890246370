import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import { GrPlan } from "react-icons/gr";
import { FaUserCircle } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { RiExchangeFundsLine } from "react-icons/ri";
import { GoProjectRoadmap } from "react-icons/go";
import { TbBrandAirtable } from "react-icons/tb";
import { CiViewList } from "react-icons/ci";
import { MdOutlineContactPhone } from "react-icons/md";
import { FaBlog } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { MdOutlineAttachEmail } from "react-icons/md";
import { GiAchievement } from "react-icons/gi";
import { DiAsterisk } from "react-icons/di";
import { SiAsterisk } from "react-icons/si";
import { GoDependabot } from "react-icons/go";
import { TbBusinessplan } from "react-icons/tb";
import { MdOutlineCarRepair } from "react-icons/md";
import { GiTrade } from "react-icons/gi";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  handleReward = () => {
    localStorage.setItem("rewardTab", "subscription");
  };
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <Link className="sidebar-brand brand-logo" to="/plans">
            <img
              src={require("../../assets/images/new_logo.png")}
              alt="logo"
              className="new-logo"
            />
          </Link>
          <Link className="sidebar-brand brand-logo-mini" to="/plans">
            <img
              src={require("../../assets/images/favLogo.png")}
              alt="logo"
              className="new-logo"
            />
          </Link>
        </div>

        <ul className="nav flex-column sub-menu">
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/plan") ? "nav-link active" : "nav-link"
              }
              to="/plans"
            >
              <GrPlan className="mr-2 side_icon" />
              <span className="side_txt">Plans</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/users") ? "nav-link active" : "nav-link"
              }
              to="/users"
            >
              <FaUserCircle className="mr-2 side_icon" />
              <span className="side_txt"> Users</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/exchange") ? "nav-link active" : "nav-link"
              }
              to="/strategy-exchange"
            >
              <RiExchangeFundsLine className="mr-2 side_icon" />
              <span className="side_txt"> Strategy Exchange </span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/strategy-risk")
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/strategy-risk"
            >
              <DiAsterisk className="mr-2 side_icon" />
              <span className="side_txt">Strategy Risk</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/strategy-pair")
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/strategy-pair"
            >
              <MdOutlineCarRepair className="mr-2 side_icon" />
              <span className="side_txt"> Strategy Pair</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/bot") ? "nav-link active" : "nav-link"
              }
              to="/bot"
            >
              <GoDependabot className="mr-2 side_icon" />
              <span className="side_txt">Bot</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/strategie") ? "nav-link active" : "nav-link"
              }
              to="/strategie"
            >
              <GoDependabot className="mr-2 side_icon" />
              <span className="side_txt">Strategie</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/faq") ? "nav-link active" : "nav-link"
              }
              to="/faq"
            >
              <FaQuestionCircle className="mr-2 side_icon" />
              <span className="side_txt">Faq </span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/cmslist") ? "nav-link active" : "nav-link"
              }
              to="/cmslist"
            >
              <CiViewList className="mr-2 side_icon" />
              <span className="side_txt">Cms</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/contactlist")
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/contactlist"
            >
              <MdOutlineContactPhone className="mr-2 side_icon" />
              <span className="side_txt">Contact us </span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/blog") ? "nav-link active" : "nav-link"
              }
              to="/blog"
            >
              <FaBlog className="mr-2 side_icon" />
              <span className="side_txt"> Blog</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/finance") ? "nav-link active" : "nav-link"
              }
              to="/finance"
            >
              <FaCoins className="mr-2 side_icon" />
              <span className="side_txt"> Finance</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              className={
                this.isPathActive("/email-template")
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/email-template"
            >
              <MdOutlineAttachEmail className="mr-2 side_icon" />
              <span className="side_txt"> Email Template</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              onClick={this.handleReward}
              className={
                this.isPathActive("/rewards") ? "nav-link active" : "nav-link"
              }
              to="/rewards"
            >
              <GiAchievement className="mr-2 side_icon" />
              <span className="side_txt">Rewards</span>
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            <Link
              onClick={this.handleReward}
              className={
                this.isPathActive("/trade-history")
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/trade-history"
            >
              <GiTrade className="mr-2 side_icon" />
              <span className="side_txt"> Trade History</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
